import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "landing-state" }
const _hoisted_2 = { class: "state-success mb-4" }
const _hoisted_3 = { class: "purchase-success-title" }
const _hoisted_4 = { class: "mt-4 text-center purchase-success-message" }

import { AvailableState } from '@/interfaces/enums/states';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LandingState',
  props: {
    state: {},
    title: {},
    message1: {},
    message2: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "16566172": (stateIconBackground.value)
}))

const props = __props;

const handleText = computed((): string => {
    return [AvailableState.success, AvailableState.error].includes(props.state) ? props.state : AvailableState.error;
});

const stateIconBackground = computed(() => {
    return handleText.value === 'success' ? 'rgb(63 143 63 / 30%)' : 'rgb(143 63 63 / 30%)';
});
const stateIcon = computed(() => {
    if (handleText.value === 'success') {
        return {
            icon: 'check',
            variant: 'success',
        };
    } else {
        return {
            icon: 'close',
            variant: 'alert',
        };
    }
});

return (_ctx: any,_cache: any) => {
  const _component_ad_icon = _resolveComponent("ad-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ad_icon, {
          icon: stateIcon.value.icon,
          variant: stateIcon.value.variant
        }, null, 8, ["icon", "variant"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_ctx.message1), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.message2), 1)
      ])
    ])
  ]))
}
}

})