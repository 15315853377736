import { createApp, markRaw } from 'vue';
import App from './App.vue';
import router from './router';
import i18nModule from './helpers/translations';
import { createPinia } from 'pinia';
import fsb from '@adcleek/front-storybook';
import BootstrapVue3 from 'bootstrap-vue-3';
import Multiselect from 'vue-multiselect';
import '@/styles/global.scss';
require('vue-multiselect/dist/vue-multiselect.min.css');

const app = createApp(App);
const pinia = createPinia();

pinia.use(({ store }) => {
    store.router = markRaw(router);
});
app.use((await i18nModule()) as any);

app.use(BootstrapVue3);
app.use(pinia);
app.use(router);
app.component('vue-multi-select', Multiselect);
fsb.install(app);
app.mount('#app');
