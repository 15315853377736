import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 h-100" }

import StripeCheckout from '@/components/Stripe/StripeCheckout.vue';
    import AccessNavbar from '@/components/Dashboard/AccessNavbar.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'StripePayment',
  setup(__props) {

    
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AccessNavbar),
    _createVNode(StripeCheckout)
  ]))
}
}

})