import { useAccessAuthStore } from '@/store/accessAuth';
import AccessFunnelIndex from '@/views/AccessFunnelIndex.vue';
import StateError from '@/views/LandingState/StateError.vue';
import StateSuccess from '@/views/LandingState/StateSuccess.vue';
import StripePayment from '@/views/StripePayment.vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/:token',
        name: 'home',
        component: AccessFunnelIndex,
    },
    {
        path: '/payment',
        name: 'payment',
        component: StripePayment,
    },
    {
        path: '/state/success',
        name: 'success',
        component: StateSuccess,
    },
    {
        path: '/state/error',
        name: 'error',
        component: StateError,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: StateError,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, _from, next) => {
    const accessAuthStore = useAccessAuthStore();
    if (to.name === 'home') {
        const jwtAccess = await accessAuthStore.getJwtAccess(to.params.token as string);
        if (!jwtAccess) {
            next({ name: 'error' });
        }
    } else if (to.name === 'payment' && !accessAuthStore.isAuthenticated) {
        next({ name: 'error' });
    }
    next();
});

export default router;
