import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "third-bloc" }

import AdsList from '../Campaigns/AdsList.vue';
    import BudgetsRecap from '../Campaigns/BudgetsRecap.vue';
    import BudgetSlider from '../Campaigns/BudgetSlider.vue';
    import DiffusionZone from '../Campaigns/DiffusionZone.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'LeftContainer',
  setup(__props) {

    
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AdsList),
    _createVNode(DiffusionZone),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BudgetSlider),
      _createVNode(BudgetsRecap)
    ])
  ]))
}
}

})