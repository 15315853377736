import { axiosInstance } from '@/axiosInstance';
import { CtlTypes } from '@adcleek/db-types';
import { defineStore } from 'pinia';
import { useAccessDataStore, useAccessOperationsStore, useGlobalStore } from './storeIndex';

export const usePurchaseCampaignsStore = defineStore('usePurchaseCampaignsStore', {
    state: () => ({
        quotation: {} as {
            quotationCode: string;
            billCode: string;
            paymentCode: string;
            couponCode: string[];
            parentCampaignCode: string;
            childCampaignCode: string[];
        },
    }),
    getters: {},
    actions: {
        async purchaseCampaigns(token: string) {
            const accessDataStore = useAccessDataStore();
            const accessOperationsStore = useAccessOperationsStore();
            const globalStore = useGlobalStore();

            const brand = globalStore.brand;
            const mediasCode = accessDataStore.getMediasToDisplay;
            const url = `/users/access-operations/purchase`;
            const campaigns = [] as CtlTypes.NewQuotationCampaignsCtlSub[];

            mediasCode.forEach((media: string) => {
                campaigns.push({
                    mediaCode: media,
                    amount: accessDataStore.budget / mediasCode.length,
                    volume: accessDataStore.impressionsCount / mediasCode.length,
                    startDate: this.formatDate(accessDataStore.getCampaignStartDate),
                    endDate: this.formatDate(accessDataStore.getCampaignEndDate),
                    creaFormat: 1,
                });
            });
            const purchaseData = {
                brand,
                posCode: accessOperationsStore.accessOperations.pos_code,
                token,
                zipCodes: accessDataStore.zipCodes,
                campaigns: campaigns,
            };
            try {
                const res = await axiosInstance.post(url, purchaseData);
                this.quotation = res.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        formatDate(date: string) {
            const [day, month, year] = date.split('/');
            return `${year}-${month}-${day}`;
        },
    },
});
