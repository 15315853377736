import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "access-funnel w-100 h-100" }
const _hoisted_2 = { class: "w-100 d-flex" }
const _hoisted_3 = { class: "left-container" }
const _hoisted_4 = { class: "right-container w-100" }

import AccessNavbar from '@/components/Dashboard/AccessNavbar.vue';
    import LeftContainer from '@/components/Dashboard/LeftContainer.vue';
    import RightContainer from '@/components/Dashboard/RightContainer.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AccessFunnelIndex',
  setup(__props) {

    
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AccessNavbar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(LeftContainer)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(RightContainer)
      ])
    ])
  ]))
}
}

})