import { axiosInstance } from '@/axiosInstance';
import { defineStore } from 'pinia';
import { useGlobalStore } from './GlobalStore';

export const useAccessAuthStore = defineStore('useAccessAuthStore', {
    state: () => ({
        isAuthenticated: false,
    }),
    getters: {},
    actions: {
        async getJwtAccess(token: string): Promise<string | undefined> {
            const GlobalStore = useGlobalStore();
            const brand = GlobalStore.brand;
            const url = `/users/access-auth/brands/${brand}/token/${token}`;
            try {
                const res = await axiosInstance.get(url);
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${res.data.jwt}`;
                axiosInstance.defaults.headers.common['brand'] = brand;
                this.isAuthenticated = true;
                return res.data.jwt as string;
            } catch (error) {
                console.log(error);
            }
        },
    },
});
