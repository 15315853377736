<template>
    <div class="budget-card">
        <b-card>
            <b-card-text class="budget-recap mb-4">
                <div>
                    <span v-thousand-digits="impressionsCount"></span>
                    <span class="unit"> {{ $t('impressions') }} </span>
                </div>
                <div>
                    <span v-cents-to-euro="budget"></span>
                </div>
            </b-card-text>
            <b-card-text class="medias-recap">
                <div class="duration">
                    {{ $t('duration') }} :
                    <div>
                        <ad-icon size="medium" icon="calendar" class="calendar-icon"></ad-icon>
                        <span>3-10 {{ $t('days') }}</span>
                    </div>
                </div>
                <div class="medias">
                    {{ $t('mediasAccess') }} :
                    <b-badge v-for="media in getMediasToDisplay" :key="media"><ad-icon :icon="media"></ad-icon></b-badge>
                </div>
            </b-card-text>
        </b-card>
    </div>
</template>

<script setup lang="ts">
import { useAccessDataStore } from '@/store/accessData';
import { storeToRefs } from 'pinia';

const accessData = useAccessDataStore();
const { impressionsCount } = storeToRefs(accessData);
let { budget } = storeToRefs(accessData);
const { getMediasToDisplay } = storeToRefs(accessData);
</script>

<style lang="scss" scoped>
.budget-card {
    :deep(.card) {
        height: 94px;
        border-radius: 8px;
        .card-body {
            padding: 16px !important;
            height: 50px !important;
        }
        .budget-recap {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 700;
            color: #1f1f1f;
            div {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .unit,
            .--euro {
                font-size: 12px;
                font-weight: 400;
                color: #707070;
            }
        }
        .medias-recap {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: #707070;
            .duration {
                display: flex;
                align-items: center;
                .fsb-icon {
                    padding: 0px;
                    margin-right: 5px;
                }
                .calendar-icon {
                    path {
                        fill: none !important;
                    }
                }
                > div {
                    display: flex;
                    align-items: center;
                    background: #f8f8f8;
                    padding: 8px;
                    height: 32px;
                    border-radius: 4px;
                    margin-left: 5px;
                }
                span {
                    font-size: 12px;
                    font-weight: 700;
                    color: #1f1f1f;
                }
            }
            .badge {
                padding: 8px;
                border-radius: 4px;
                background: #f8f8f8;
                color: #1f1f1f;
                margin-right: 5px;
            }
        }
    }
}
</style>
