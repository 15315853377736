import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import LandingState from '@/components/LandingState/LandingState.vue';
    import { useI18n } from 'vue-i18n';
    import { AvailableState } from '@/interfaces/enums/states';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'StateSuccess',
  setup(__props) {

    const { t } = useI18n({ useScope: 'global' });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LandingState, {
    state: _unref(AvailableState).success,
    title: _unref(t)('purchaseTitleSuccess'),
    message1: _unref(t)('purchaseMessageSuccess'),
    message2: _unref(t)('purchaseMessageSuccess2')
  }, null, 8, ["state", "title", "message1", "message2"]))
}
}

})