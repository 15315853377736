import { axiosInstance } from '@/axiosInstance';
import { User } from '@adcleek/db-types';
import { defineStore } from 'pinia';
import { useGlobalStore } from './GlobalStore';

export const useAccessOperationsStore = defineStore('useAccessOperationsStore', {
    state: () => ({
        accessOperations: {} as User.AccessOperationsDb,
    }),
    getters: {},
    actions: {
        async getAccessOperations(token: string) {
            const GlobbalStore = useGlobalStore();
            const brand = GlobbalStore.brand;
            const url = `/users/access-operations/brands/${brand}/token/${token}`;
            try {
                const res = await axiosInstance.get(url);
                this.accessOperations = res.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    },
});
