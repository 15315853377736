import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fsb-typo-heading-5" }
const _hoisted_2 = ["min", "max", "disabled"]
const _hoisted_3 = { class: "slider-value mb-3" }

import { watch, computed, nextTick } from 'vue';
import { useAccessDataStore } from '@/store/accessData';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'BudgetSlider',
  setup(__props) {

_useCssVars(_ctx => ({
  "7470b60c": (sliderGradientStyle.value)
}))

const accessData = useAccessDataStore();
const { minBudget, maxBudget } = storeToRefs(accessData);
let { budget, selectedOperations } = storeToRefs(accessData);

// on change selected operations budget always reset to min

const mb = computed(() => {
    return maxBudget.value > 0 ? maxBudget.value : 20000;
});

const sliderGradientStyle = computed(() => {
    // permet de calculer la coloration du slider en fonction de la valeur actuel
    const progress = ((budget.value - minBudget.value) / (mb.value - minBudget.value)) * 100;
    return `linear-gradient(to right, #619ef9 0%, #619ef9 ${progress}%, #fff ${progress}%, #fff 100%)`;
});

watch(
    () => selectedOperations.value,
    () => {
        nextTick(() => {
            budget.value = minBudget.value;
        });
    }
);

return (_ctx: any,_cache: any) => {
  const _directive_cents_to_euro = _resolveDirective("cents-to-euro")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h5", _hoisted_1, "3- " + _toDisplayString(_ctx.$t('defineYourBudget')), 1),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(budget) ? (budget).value = $event : budget = $event)),
      type: "range",
      min: _unref(minBudget),
      max: mb.value,
      step: "2500",
      disabled: _unref(selectedOperations).length === 0,
      class: "custom-slider"
    }, null, 8, _hoisted_2), [
      [_vModelText, _unref(budget)]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("span", null, null, 512), [
          [_directive_cents_to_euro, _unref(minBudget)]
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "ml-1" }, "min", -1))
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("span", null, null, 512), [
          [_directive_cents_to_euro, _unref(maxBudget)]
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "ml-1" }, "max", -1))
      ])
    ])
  ]))
}
}

})