import { defineStore } from 'pinia';
import { accessStoreState } from '@/interfaces/stores/accessStoreState';

export const useAccessDataStore = defineStore('useAccessDataStore', {
    state: () =>
        ({
            selectedOperations: [],
            zipCodes: [],
            budget: 0,
            minAmount: 20000,
            maxAmount: 30000,
        } as accessStoreState),
    getters: {
        minBudget(state): number {
            return state.minAmount * state.selectedOperations.length;
        },
        maxBudget(state): number {
            return state.maxAmount * state.selectedOperations.length;
        },
        budgetInEuros(state): number {
            return state.budget / 100;
        },
        impressionsCount(): number {
            return (this.budgetInEuros * 1000) / 2.5;
        },
        getCampaignStartDate(): string {
            const today = new Date();
            today.setDate(today.getDate() + 3);
            return today.toLocaleDateString();
        },
        getCampaignEndDate(): string {
            const startDate = new Date(this.getCampaignStartDate);
            startDate.setDate(startDate.getDate() + 10);
            return startDate.toLocaleDateString();
        },
        getMediasToDisplay(state): string[] {
            const mediasIcon: string[] = ['DSK'];
            if (state.budget >= 39900) {
                mediasIcon.push('MOB');
            }
            if (state.budget >= 59900) {
                mediasIcon.push('FBK');
            }
            return mediasIcon;
        },
    },
    actions: {},
});
