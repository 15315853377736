import { axiosInstance } from '@/axiosInstance';
import { defineStore } from 'pinia';
import { useAccessDataStore } from './accessData';
import { usePurchaseCampaignsStore } from './purhaseCampaigns';
import { useGlobalStore } from './GlobalStore';
import { useAccessOperationsStore } from './accessOperations';

export const useStripeStore = defineStore('useStripeStore', {
    state: () => ({
        sessionId: '',
        clientSecret: '',
    }),
    getters: {},
    actions: {
        async createCheckoutSession(): Promise<string> {
            try {
                const { budget } = useAccessDataStore();
                const { brand } = useGlobalStore();
                const { quotation } = usePurchaseCampaignsStore();
                const { accessOperations } = useAccessOperationsStore();

                const data = {
                    amount: budget,
                    metadata: {
                        POI: accessOperations.pos_code,
                        billCode: quotation.billCode,
                        brand,
                        paymentType: 'card',
                        reference: quotation.quotationCode,
                    },
                };
                const response = await axiosInstance.post(`/payment/access/session/create-checkout`, data);
                this.sessionId = response.data.sessionId;
                this.clientSecret = response.data.clientSecret;
                return this.clientSecret;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    },
});
