<template>
    <b-navbar class="access-navbar" toggleable="lg">
        <b-navbar-nav class="m-auto">
            <img src="../../assets/logo_spoticar.png" alt="logo_spoticar" />
        </b-navbar-nav>
    </b-navbar>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.access-navbar {
    height: 64px;
    background: #dbdbdb;
}
</style>
