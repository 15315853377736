<template>
    <div id="checkout"></div>
</template>

<script setup lang="ts">
    import { useStripeStore } from '@/store/stripe';
    import { loadStripe, Stripe } from '@stripe/stripe-js';
    import { onMounted, ref } from 'vue';

    const stripeStore = useStripeStore();

    const stripe = ref<Stripe | null>(null);

    const initialize = async (stripe: Stripe) => {
        try {
            const clientSecret = stripeStore.clientSecret || (await stripeStore.createCheckoutSession());
            const checkout = await stripe.initEmbeddedCheckout({ clientSecret });
            checkout.mount('#checkout');
        } catch {
            // TODO: not yet designed by UX, what to do?
            console.error('Error initializing Stripe checkout');
        }
    };

    onMounted(async () => {
        stripe.value = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        if (stripe.value) {
            initialize(stripe.value);
        }
    });
</script>

<style lang="scss" scoped>
    #checkout {
        width: 100%;
        height: calc(-64px + 100vh);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: rgb(255, 255, 255);
    }

    #checkout iframe {
        width: 100% !important;
        height: 100% !important;
        border: none;
    }
</style>
