<template>
    <landing-state :state="AvailableState.success" :title="t('purchaseTitleSuccess')" :message1="t('purchaseMessageSuccess')" :message2="t('purchaseMessageSuccess2')"></landing-state>
</template>

<script lang="ts" setup>
    import LandingState from '@/components/LandingState/LandingState.vue';
    import { useI18n } from 'vue-i18n';
    import { AvailableState } from '@/interfaces/enums/states';

    const { t } = useI18n({ useScope: 'global' });
</script>

<style lang="scss"></style>
