import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "checkout" }

import { useStripeStore } from '@/store/stripe';
    import { loadStripe, Stripe } from '@stripe/stripe-js';
    import { onMounted, ref } from 'vue';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'StripeCheckout',
  setup(__props) {

    const stripeStore = useStripeStore();

    const stripe = ref<Stripe | null>(null);

    const initialize = async (stripe: Stripe) => {
        try {
            const clientSecret = stripeStore.clientSecret || (await stripeStore.createCheckoutSession());
            const checkout = await stripe.initEmbeddedCheckout({ clientSecret });
            checkout.mount('#checkout');
        } catch {
            // TODO: not yet designed by UX, what to do?
            console.error('Error initializing Stripe checkout');
        }
    };

    onMounted(async () => {
        stripe.value = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        if (stripe.value) {
            initialize(stripe.value);
        }
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})