<template>
    <div class="h-100">
        <div class="recap" v-if="showRecapCard">
            <div>
                <span class="unit" v-thousand-digits="impressionsCount"></span>
                <span> {{ $t('impressions') }} </span>
            </div>
            <div>
                {{ accessData.getCampaignStartDate }}
                <span class="d-flex align-items-center">
                    {{ $t('startDateAccess') }}
                    <ad-icon size="meduim" icon="info" variant="white" class="cursor-pointer ml-1" ref="targetRef" />

                    <base-tooltip :target="targetRef">
                        <div class="fsb-typo-para-regular">
                            {{ $t('recapCampaignAccessTooltip') }}
                        </div>
                    </base-tooltip>
                </span>
            </div>
            <div>
                <span class="budget" v-cents-to-euro="budget"></span>
                <span>{{ $t('budgetTotalHT') }}</span>
            </div>
            <div>
                <ui-button :label="$t('purchaseAccess')" variant="white" @click="openPurchaseModal" />
            </div>

            <base-modal
                :modal-show="showPurchaseModal"
                :title="$t('confirmPurchase')"
                hide-header-close
                :tertiary-label="$t('cancel')"
                :show-secondary-button="false"
                :primary-label="$t('confirm')"
                :on-tertiary-button-click="closeModal"
                :on-primary-button-click="confirmPurchase"
                :is-primary-button-disabled="!acceptCgu"
            >
                <div class="fsb-typo-para-regular" style="color: var(--grayscale-gray-dark)">
                    {{ $t('estimatePrompt') }}
                </div>

                <div class="d-flex flex-row justify-content-between fsb-typo-para-medium">
                    <span>{{ $t('purchaseAmountHT') }}</span>
                    <span v-cents-to-euro="budget"></span>
                </div>
                <hr />
                <div class="d-flex flex-row justify-content-between fsb-typo-para-medium">
                    <span>{{ $t('amountTTC') }}</span>
                    <span class="fsb-typo-heading-5" v-cents-to-euro="budget * 1.2"></span>
                </div>

                <base-checkbox name="acceptCgu" @update:checked="(value: boolean) => (acceptCgu = value)">
                    <span class="fsb-typo-btn-secondary mr-1">{{ $t('acceptCguText') }}</span>
                    <span class="fsb-typo-link-regular cursor-pointer" @click="downloadCGUPdf">{{ $t('acceptCguLink') }}</span>
                </base-checkbox>
            </base-modal>
        </div>

        <div class="mobile-mockup">
            <img src="../../assets/access-mobileMockup.png" alt="mobile-mockup" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useAccessDataStore, usePurchaseCampaignsStore } from '@/store/storeIndex';
    import { storeToRefs } from 'pinia';
    import { computed, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    const accessData = useAccessDataStore();
    const purchaseCampaigns = usePurchaseCampaignsStore();

    const { budget, selectedOperations, zipCodes } = storeToRefs(accessData);
    const { impressionsCount } = storeToRefs(accessData);

    const showRecapCard = computed(() => {
        return selectedOperations.value.length && zipCodes.value.length;
    });

    const targetRef = ref(null);

    const showPurchaseModal = ref(false);
    const acceptCgu = ref(false);
    const openPurchaseModal = () => {
        showPurchaseModal.value = true;
    };
    const closeModal = () => {
        showPurchaseModal.value = false;
        acceptCgu.value = false;
    };

    const downloadCGUPdf = () => {
        const pdfUrl = process.env.VUE_APP_CGV;
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        } else {
            console.error('CGU PDF URL not found in environment variables');
        }
    };

    const router = useRouter();
    const route = useRoute();
    const confirmPurchase = async () => {
        try {
            await purchaseCampaigns.purchaseCampaigns(route.params.token as string);
            router.replace({ name: 'payment' });
        } catch {
            router.push({ name: 'error' });
        }
    };
</script>

<style scoped lang="scss">
    .recap {
        background: #619ef9;
        color: #fefefe;
        font-weight: 500;
        font-size: 14px;
        height: 72px;
        width: 604px;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 24px auto;
        .fsb-ui-button {
            font-weight: 500;
        }
        div {
            display: grid;
            gap: 4px;
            .budget,
            .unit {
                font-weight: 500;
                font-size: 14px;
            }
            span {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    .cursor-pointer {
        cursor: pointer;
    }

    .mobile-mockup {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
</style>
