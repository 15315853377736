<template>
    <div>
        <h5 class="fsb-typo-heading-5">3- {{ $t('defineYourBudget') }}</h5>
        <input v-model="budget" type="range" :min="minBudget" :max="mb" step="2500" :disabled="selectedOperations.length === 0" class="custom-slider" />
        <div class="slider-value mb-3">
            <div>
                <span v-cents-to-euro="minBudget"></span>
                <span class="ml-1">min</span>
            </div>
            <div>
                <span v-cents-to-euro="maxBudget"></span>
                <span class="ml-1">max</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { watch, computed, nextTick } from 'vue';
import { useAccessDataStore } from '@/store/accessData';
import { storeToRefs } from 'pinia';

const accessData = useAccessDataStore();
const { minBudget, maxBudget } = storeToRefs(accessData);
let { budget, selectedOperations } = storeToRefs(accessData);

// on change selected operations budget always reset to min

const mb = computed(() => {
    return maxBudget.value > 0 ? maxBudget.value : 20000;
});

const sliderGradientStyle = computed(() => {
    // permet de calculer la coloration du slider en fonction de la valeur actuel
    const progress = ((budget.value - minBudget.value) / (mb.value - minBudget.value)) * 100;
    return `linear-gradient(to right, #619ef9 0%, #619ef9 ${progress}%, #fff ${progress}%, #fff 100%)`;
});

watch(
    () => selectedOperations.value,
    () => {
        nextTick(() => {
            budget.value = minBudget.value;
        });
    }
);
</script>

<style lang="scss" scoped>
.third-bloc {
    .custom-slider {
        height: 8px;
        scrollbar-color: #fff;
        border-radius: 3px;
        margin-bottom: 10px;
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        background: v-bind('sliderGradientStyle');
        outline: none;

        &::-webkit-slider-thumb {
            width: 15px;

            height: 15px;
            border-radius: 10px;
            border: 4px;
            cursor: pointer;
            -webkit-appearance: none;
            appearance: none;
            background: radial-gradient(circle, #fff 30%, #619ef9 30%);
        }

        &::-moz-range-thumb {
            width: 15px;
            height: 15px;
            border-radius: 10px;
            border: 4px;
            cursor: pointer;
            -webkit-appearance: none;
            appearance: none;
            background-color: #619ef9;
        }
    }
    .slider-value {
        display: flex;
        justify-content: space-between;
        span {
            color: #dbdbdb;
            font-size: 12px;
            font-weight: 400;
        }
    }
}
</style>
