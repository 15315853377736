<template>
    <div class="first-bloc mb-4">
        <h5 class="fsb-typo-heading-5">1- {{ $t('selectAccessOperations') }}</h5>
        <label class="sub-title fsb-typo-para-regular">
            {{ $t('publishedAds') }}
            <span>●</span>
        </label>
        <vue-multi-select
            v-model="selectedOperations"
            :options="operationsOptions"
            :multiple="true"
            :close-on-select="false"
            :max-height="192"
            :show-labels="false"
            :searchable="false"
            label="name"
            track-by="name"
            :placeholder="$t('adsToHighlight')"
        >
            <template #option="props">
                <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span
                    ><span class="option__small">{{ props.option.date }}</span>
                </div>
            </template>

            <template #selection="{ values }">
                <span class="multiselect__single" v-if="values.length">{{ values.length }} {{ $t('selectedAds') }}</span>
            </template>
        </vue-multi-select>
    </div>
</template>

<script setup lang="ts">
import { useAccessOperationsStore } from '@/store/accessOperations';
import { useAccessDataStore } from '@/store/storeIndex';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const accessData = useAccessDataStore();

const { selectedOperations } = storeToRefs(accessData);

const accessOperationsStore = useAccessOperationsStore();

const { accessOperations } = storeToRefs(accessOperationsStore);
const operationsOptions = ref([] as { name: string; date: string }[]);

watch(
    () => accessOperations.value,
    (operations) => {
        operationsOptions.value = operations.details.products_list.map((product) => {
            return {
                name: product.name,
                date: product.car_add_date,
            };
        });
    }
);

onMounted(async () => {
    const token = route.params.token as string;
    try {
        await accessOperationsStore.getAccessOperations(token);
    } catch {
        router.push({ name: 'error' });
    }
});
</script>

<style lang="scss" scoped>
.first-bloc {
    :deep(.multiselect__tags) {
        border-radius: 8px;
        padding: 10px 40px 0 8px;
        .multiselect__placeholder {
            color: #707070;
            font-size: 12px;
            font-weight: 400;
        }
    }
    .multiselect__input,
    .multiselect__single {
        font-size: 12px;
        font-weight: 400;
        color: #1f1f1f;
    }

    :deep(.multiselect__content-wrapper) {
        .multiselect__content {
            .multiselect__element {
                .multiselect__option {
                    font-size: 14px;
                    font-weight: 500;
                    color: #1f1f1f;
                    &--highlight {
                        background: rgba(97, 158, 249, 0.4);
                    }
                    &--selected {
                        background: #619ef9;
                        color: #f6f6f6;
                        .option__small {
                            color: #f6f6f6 !important;
                        }
                    }
                    .option {
                        &__desc {
                            display: flex;
                            justify-content: space-between;
                        }
                        &__small {
                            color: rgba(112, 112, 112, 1);
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
</style>
