<template>
    <div class="landing-state">
        <div>
            <div class="state-success mb-4">
                <ad-icon :icon="stateIcon.icon" :variant="stateIcon.variant"></ad-icon>
            </div>
            <div class="purchase-success-title">
                <div>
                    <h1>{{ title }}</h1>
                </div>
            </div>

            <div class="mt-4 text-center purchase-success-message">
                <div>
                    {{ message1 }}
                </div>
                <div>
                    {{ message2 }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { AvailableState } from '@/interfaces/enums/states';
import { computed } from 'vue';

const props = defineProps<{
    state: AvailableState;
    title: string;
    message1: string;
    message2?: string;
}>();

const handleText = computed((): string => {
    return [AvailableState.success, AvailableState.error].includes(props.state) ? props.state : AvailableState.error;
});

const stateIconBackground = computed(() => {
    return handleText.value === 'success' ? 'rgb(63 143 63 / 30%)' : 'rgb(143 63 63 / 30%)';
});
const stateIcon = computed(() => {
    if (handleText.value === 'success') {
        return {
            icon: 'check',
            variant: 'success',
        };
    } else {
        return {
            icon: 'close',
            variant: 'alert',
        };
    }
});
</script>

<style lang="scss">
.landing-state {
    $text-container-width: 600px;
    background: var(--primary-color);
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    color: #fff;
    text-align: center;

    .state-success {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: v-bind(stateIconBackground);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        .fsb-icon {
            transform: scale(3);
        }
    }

    h1 {
        font-weight: bolder;
    }
    .purchase-success-title {
        width: 100%;
        display: flex;
        justify-content: center;
        > div {
            width: $text-container-width;
        }
    }
    .purchase-success-message {
        width: $text-container-width;
        font-size: 22px;
        > div {
            line-height: 25px;
        }
    }
}
</style>
